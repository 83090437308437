import React from "react";
import "./css/app.css";
import MapFunc from "./components/map";
import LoginButton from "./components/loginScreen";
import LogoutButton from "./components/logoutbutton";
import { useAuth0 } from "@auth0/auth0-react";
import TableModal from "./components/table";
import CUIBanner from "./components/cui-banner";

function App() {
  const { isLoading, error } = useAuth0();
  return (
    <main>
      {error && <p>Authentication Error</p>}
      {!error && isLoading && <p>Loading...</p>}
      {!error && !isLoading && (
        <>
          <CUIBanner />
          <TableModal />
          <LoginButton />
          <LogoutButton />
          <MapFunc />
        </>
      )}
    </main>
  );
}
export default App;
