import React, { useState } from "react";
import { closeModal } from "./map";
import "../css/table-modal.css";
import "../css/table-entry.css";
import baseData from "../basedata/base-data.json";

function getAvailableADSNs() {
  const availableADSNs = [];
  for (let i = 0; i < baseData.length; i++) {
    if (!availableADSNs.includes(baseData[i].adsn)) {
      availableADSNs.push(baseData[i].adsn);
    }
  }
  return availableADSNs;
}

function queryForBase(baseName) {
  const tableQuerySelector = document.getElementById("tableQuerySelector");
  const tableQueryInput = document.getElementById("tableQueryInput");
  tableQuerySelector.value = "site_name";
  tableQueryInput.value = baseName || "";
}

function TableModal() {
  const adsnOptions = [];
  const [adsnValue, setAdsn] = useState(4012);
  const [baseValue, setBase] = useState("");
  const tableFormattedObjects = [];
  const changeAdsn = (event) => {
    setAdsn(event.target.value);
  };
  const changeBase = (event) => {
    setBase(event.target.value);
  };
  for (let i = 0; i < baseData.length; i++) {
    if (
      baseData[i].adsn == adsnValue &&
      baseData[i].site_name
        .toString()
        .toLowerCase()
        .includes(baseValue.toLowerCase())
    ) {
      tableFormattedObjects.push(
        <div className="table-entry-container">
          <div className="table-adsn-container table-content-container">
            <h5>{baseData[i].adsn}</h5>
          </div>
          <div
            className="table-base-container table-content-container flex-width"
            onClick={() => {
              setBase(baseData[i].site_name);
              queryForBase(baseData[i].site_name);
            }}
          >
            <h5>{baseData[i].site_name}</h5>
          </div>
          <div className="table-organization-container table-content-container flex-width">
            <h5>{baseData[i].organization}</h5>
          </div>
          <div className="table-zip-container table-content-container">
            <h5>{baseData[i].zip}</h5>
          </div>
          <div className="table-pascode-container table-content-container">
            <h5>{baseData[i].pascode}</h5>
          </div>
        </div>
      );
    }
  }
  for (let i = 0; i < getAvailableADSNs().length; i++) {
    const formattedADSN = <option>{getAvailableADSNs()[i]}</option>;
    adsnOptions.push(formattedADSN);
  }

  return (
    <div id="modal" className="page-container">
      <div className="modal-container">
        <div className="modal-header">
          <div className="modal-header-adsn-container">
            <div className="formlike-container">
              <label>ADSN:</label>
              <select onChange={changeAdsn} id="selectedADSN">
                {adsnOptions}
              </select>
            </div>
          </div>
          <div>
            <div className="formlike-container">
              <select id="tableQuerySelector">
                {/* <option disabled value>--Please choose an option--</option> */}
                {/* <option value="component">Component</option> */}
                <option selected value="site_name">
                  Base/Site Name
                </option>
                {/* <option value="organization">Organization</option>
                            <option value="zip">ZIP</option> */}
              </select>
              <input onChange={changeBase} id="tableQueryInput"></input>
            </div>
          </div>
          <div className="modal-header-x-icon-container">
            <i onClick={closeModal} class="fa-solid fa-x"></i>
          </div>
        </div>
        <div className="modal-table">
          <div className="table-entry-container">
            <div className="table-adsn-container table-content-container">
              <h5>ADSN</h5>
            </div>
            <div className="table-base-container table-content-container flex-width">
              <h5>Base</h5>
            </div>
            <div className="table-organization-container table-content-container flex-width">
              <h5>Organization</h5>
            </div>
            <div className="table-zip-container table-content-container">
              <h5>ZIP Code</h5>
            </div>
            <div className="table-pascode-container table-content-container">
              <h5>PASCODE</h5>
            </div>
          </div>

          {tableFormattedObjects}
        </div>
      </div>
    </div>
  );
}

export default TableModal;
export { queryForBase };
