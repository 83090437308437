import { useAuth0 } from "@auth0/auth0-react";
import logo from "../316cptslogo.jpg";
import "../css/login.css";
const LoginButton = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  return (
    !isAuthenticated && (
      <>
        <div className="login-page-container">
          <div className="login-info-container">
            <img src={logo} alt="316 CPTS" className="squadron-logo"></img>
            <div className="login-words-container">
              <h1 className="title">316th CPTS</h1>
              <h3 className="subtitle">Data Visualizer</h3>
              <button className="button" onClick={() => loginWithRedirect()}>
                Sign In
              </button>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default LoginButton;
