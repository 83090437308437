import { React, useState } from "react";
import { Marker, Popup, MapContainer, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";
import "../css/map.css";
import baseData from "../basedata/base-data.json";
import { useAuth0 } from "@auth0/auth0-react";
import L from "leaflet";
import { queryForBase } from "./table";

function closeModal() {
  const modal = document.getElementById("modal");
  if (modal) {
    modal.style.visibility = "hidden";
  }
}

function openModal(selectedBaseInformation) {
  const modal = document.getElementById("modal");
  queryForBase(selectedBaseInformation.site_name);
  modal.style.visibility = "visible";
}

function MapFunc() {
  const { isAuthenticated } = useAuth0();
  const [query, setQuery] = useState("");
  const [selectOption, setOption] = useState("component");

  L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  });

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setQuery(inputValue);
  };

  const handleOptionChange = (event) => {
    const selectValue = event.target.value;
    setQuery("");
    setOption(selectValue);
  };

  const filteredState = baseData.filter((data) => {
    if (!data[selectOption]) {
      console.log(selectOption + " not in current dataset");
      return false;
    } else {
      return data[selectOption]
        .toString()
        .toLowerCase()
        .includes(query.toLowerCase());
    }
  });

  return (
    isAuthenticated && (
      <MapContainer
        center={[38.7965203, -76.8836261]}
        zoom={13}
        scrollWheelZoom={true}
      >
        <div class="background-for-thing">
          <select
            value={selectOption}
            id="queryOptions"
            onChange={handleOptionChange}
          >
            <option disabled selected value>
              --Please choose an option--
            </option>
            <option value="component">Component</option>
            {/* <option value="state_terr">State/Territory</option> */}
            {/* <option value="country">Country</option> */}
            <option value="site_name">Site Name</option>
            <option value="organization">Organization</option>
            <option value="zip">ZIP</option>
            <option value="adsn">ADSN</option>
          </select>
          <input
            id="baseSearchbar"
            value={query}
            type="text"
            placeholder="Search.."
            className="searchBar"
            onChange={handleInputChange}
          ></input>
          <i
            onClick={openModal}
            id="modal-popout-button"
            class="fa-regular fa-window-maximize"
          ></i>
        </div>
        <TileLayer
          noWrap={true}
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MarkerClusterGroup chunkedLoading>
          {filteredState.map((baseData) => (
            <Marker
              key={baseData.id}
              position={[baseData.geo_point_2d.lat, baseData.geo_point_2d.lon]}
            >
              <Popup className="popup-class">
                <h3>
                  {baseData.site_name}
                </h3>
                <ul>
                  <p>Unit: {baseData.organization}</p>
                  <p>ADSN: {baseData.adsn}</p>
                  <p>PasCode: {baseData.pascode}</p>
                  <div
                    onClick={() => openModal(baseData)}
                    className="open-table-container"
                  >
                    <h5>View in Table..</h5>
                  </div>
                </ul>
              </Popup>
            </Marker>
          ))}
        </MarkerClusterGroup>
      </MapContainer>
    )
  );
}

export default MapFunc;
export { closeModal, openModal };
